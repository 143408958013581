import { AfterViewInit,  ChangeDetectionStrategy,  ChangeDetectorRef,  Component, ElementRef, forwardRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToggleService } from './toggle.service';

export interface IToggleOption {
  id?: any
  title: string
  value: any
  class?: string
}

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true
  }],
})
export class ToggleComponent implements  ControlValueAccessor, AfterViewInit, OnChanges, OnInit {

  @Input('color') color?: string
  @Input('options') options: Array<IToggleOption> = []
  @Input('disabled') manualDisable: boolean

  value: any
  disabled: boolean = false
  valueChanged?: (value: any) => void
  onTouched?: () => void

  constructor(private service: ToggleService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    //this.cdr.detach();
    

  }
  ngOnChanges() {
    if (this.options) {
      this.service.setIdOnEachOption(this.options)
    }
    //this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    if (this.NobHtml)
      this.service.initToggle(this.NobHtml, this.value, this.options)

  

  }

  toggleClicked() {
    // if no value then select first item by default on click
    

    if (this.value == null || this.value == undefined) {
      this.value = this.options[0].value
    }

    if (this.NobHtml)
      this.value = this.service.toggle(this.NobHtml, this.value, this.options)

    this.valueChanged && this.valueChanged(this.value)

    //this.cdr.markForCheck();
    this.cdr.detectChanges();

  }

  writeValue(val: any): void {
    this.value = val

    if (this.NobHtml) {
      this.service.initToggle(this.NobHtml, this.value, this.options)
    }

  }

  registerOnChange(fn: any): void {
    this.valueChanged = fn

   
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
   


  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  @ViewChild('toggleContainer') toggleContainerRef?: ElementRef<HTMLElement>
  get ToggleContainerHtml() {
    return this.toggleContainerRef?.nativeElement
  }

  @ViewChild('nob') nobRef?: ElementRef<HTMLElement>
  get NobHtml() {
    return this.nobRef?.nativeElement
  }
}
