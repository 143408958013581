import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertBadgeComponent } from './alert-badge/alert-badge.component';
import { OkDialogComponent } from './okdialog/okdialog.component';
import { ToggleModule } from './toggle/toggle.module';
import { YesNoDialogComponent } from './yesnodialog/yesnodialog.component';

@NgModule({
  declarations: [
    AlertBadgeComponent,
    OkDialogComponent,
    YesNoDialogComponent,
  ],
  imports: [
    MatDialogModule,
    ToggleModule,
    MatButtonModule,
    CommonModule

  ],
  exports: [
    AlertBadgeComponent,
    OkDialogComponent,
    YesNoDialogComponent,
    ToggleModule,
  ],
  providers: [
  ]
})
export class UIUtilityComponentsModule { }
