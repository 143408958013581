import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alert-badge',
  templateUrl: './alert-badge.component.html',
  styleUrls: ['./alert-badge.component.scss']
})
export class AlertBadgeComponent implements OnInit {

  constructor() { }

  @Input() heading? : string = 'Attention Required';
  @Input() message : string ;

  ngOnInit(): void {


  }

}
