import { Clipboard } from '@angular/cdk/clipboard';
import {Component, Inject, Optional} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'mobile-app-upgrade',
    templateUrl: 'mobileapp-upgrade.component.html',
    styleUrls:['mobileapp-upgrade.component.scss']
  })
  export class MobileAppUpgradeDialogComponent {

    accesscode : string;
    constructor( @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private clipboard: Clipboard, private snackbar: MatSnackBar) {
        this.accesscode = data.accessCode;
    }

    copyLink() {

        this.clipboard.copy(this.accesscode);
        this.snackbar.open("Access code copied", "dismiss", {
            duration: 1000,
          });

    }

 
  }