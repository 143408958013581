<h2 mat-dialog-title>New Version Available</h2>
<div mat-dialog-content class="dialog-content">
  <a href="{{data.storeLink}}"> <img width="180px" src="{{data.image}}"> </a>

  <p class="primary-message">Please uninstall this web app and download the official App from {{data.storeName}} </p>
  <p class="secondary-message">
    Copy your access code:
  </p>

  <div class="access-code flex flex-row items-center justify-start flex-none">
    <div>{{data.accessCode}}</div>
    <div><button mat-button tabindex="1" autofocus (click)="copyLink()">Copy</button> </div>
  </div>
</div>

<div mat-dialog-actions>
  <a mat-raised-button href="{{data.storeLink}}" color="primary" tabindex="2" mat-dialog-close>{{data.buttonText}}</a>
  <a mat-raised-button color="accent" tabindex="3" mat-dialog-close>Later</a>
</div>
