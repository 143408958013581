import { Platform } from '@angular/cdk/platform';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { MobileAppUpgradeDialogComponent } from '../shared/mobileapp-upgrade/mobileapp-upgrade.component';
import { App, AppState } from '@capacitor/app';
import { RestAPIService } from './restapi.service';
import { Router } from '@angular/router';
import { AppVersion } from '../interfaces/appversion.interface';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { Dialog } from '@capacitor/dialog';


@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  public storeName = '';
  public storeLogo = '';
  public storeLink = '';
  private deployKey = '';

  constructor(

    private dialog: MatDialog,
    private ngPlatform: Platform,
    private api: RestAPIService,
    private router: Router,
    private ngZone: NgZone) {

 

  }

  initialize() {

    // if platforms is browser on android (instead of app)
    if (this.platform === 'web' && this.ngPlatform.ANDROID) {
      this.storeName = 'Google Play'
      this.storeLogo = '/assets/download-google-store.png';
      this.storeLink = environment.appVersion.googleAppUrl;


    }
    // if platforms is browser on IOS (instead of app)
    else if (this.platform === 'web' && this.ngPlatform.IOS) {
      this.storeName = 'Apple Appstore';
      this.storeLogo = '/assets/download-apple-store.png';
      this.storeLink = environment.appVersion.appleAppUrl;
    }
    // for testing only. This browser on PC
    else {
      this.storeName = 'Google Play'
      this.storeLogo = '/assets/download-google-store.png';
      this.storeLink = environment.appVersion.googleAppUrl;
    }


    if (this.platform === 'ios')
      this.deployKey = environment.appVersion.IOS_DEPLOY_KEY;

    if (this.platform === 'android')
      this.deployKey = environment.appVersion.ANDROID_DEPLOY_KEY;

    this.api.getAppVersion().subscribe((app: AppVersion) => {
      if (app.minimumRequired > environment.appVersion.currentVersion) {
        this.router.navigate(['update-required']);
      }
    });
  }

  async performNativeUpdate()  {

    if (!Capacitor.isNativePlatform()) return;

    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate()
    }
    else if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
    else {
      Dialog.confirm({
        message: "A new update is available",
        cancelButtonTitle: "Cancel",
        okButtonTitle: "Update",
        title: "Update required"
      }).then((result)=>{
        if (result.value)
          AppUpdate.openAppStore();

      });

    }
  }
 
  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.currentVersionCode;
    } else {
      return result.currentVersionName;
    }
  };

  
  public checkForUpdates() {
    // if this is definitely not a mobile app, execute PWA update
    if (Capacitor.isNativePlatform()) {
      this.setAppStatusBarBackground(); // only works on android
      this.performNativeUpdate();
      // .then(()=>{

      //   // check if Mobile App version (Binary appstore release) is compatible with the backend
      //   this.api.getAppVersion().subscribe((app: AppVersion) => {
      //     if (app.minimumRequired > environment.appVersion.currentVersion) {
      //       this.router.navigate(['update-required']);
      //     }
      //     else {
      //       // Activate Microsoft codepush service on mobile device
      //       // for incremental web releases
      //       this.enableCodePush();
      //     }
      //   });

      // })

    }
  }


  public get platform() {
    return Capacitor.getPlatform();

  }

  showMobileAppInstallAlert(accessCode: string) {

    // if running on web and app versions are available for this build 
    if (this.platform === 'web' && environment.appVersion.appStoreRedirection) {

      this.dialog.open(MobileAppUpgradeDialogComponent, {
        data: {
          image: this.storeLogo,
          storeLink: this.storeLink,
          buttonText: 'Download',
          accessCode: accessCode,
          storeName: this.storeName

        }
      });
    }
  }

  setAppStatusBarBackground() {

    if (this.platform !== 'web')
      setTimeout(() => {

        StatusBar.setStyle({ style: Style.Dark }).then(() => { });
        StatusBar.setBackgroundColor({ color: "#00695C" }).then(() => { });

      }, 200); // slight delay seems neccessary or the app bar doesn't initiate. Possibly android 13 bug
  }



}